import tw from "tailwind-styled-components";
import NextImage from "../next-image/next-image";

export const HideMobile = tw.div`
  ${({ $hide }) => $hide && "hidden desktop:block"}
`;

export const ReputationCardWrapper = tw.li`
  flex
  flex-col
  gap-2
  items-center
  justify-center
  bg-gray-100
  rounded-2xl
  py-9
  px-9
  hover:bg-gray-200
`;

export const ReputationImage = tw(NextImage)`
  w-auto
`;

export const ReputationOpinion = tw.p`
  text-base
  text-center
`;
