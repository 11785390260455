import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import {
  ReputationCardWrapper,
  ReputationOpinion,
  ReputationImage,
  HideMobile,
} from "./styles";

const Link = dynamic(() => import("../link/link"));

export const ReputationCard = ({
  logoImage,
  opinionMessage,
  hideOnMobile,
  link,
}) => {
  return (
    <HideMobile $hide={hideOnMobile}>
      {link ? (
        <Link {...link} noUnderline>
          <ReputationCardWrapper>
            {logoImage && <ReputationImage media={logoImage} />}
            {opinionMessage && (
              <ReputationOpinion>
                {renderLineBreaks(opinionMessage)}
              </ReputationOpinion>
            )}
          </ReputationCardWrapper>
        </Link>
      ) : (
        <ReputationCardWrapper>
          {logoImage && <ReputationImage media={logoImage} />}
          {opinionMessage && (
            <ReputationOpinion>
              {renderLineBreaks(opinionMessage)}
            </ReputationOpinion>
          )}
        </ReputationCardWrapper>
      )}
    </HideMobile>
  );
};



export default ReputationCard;
